import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef } from 'react'
import Amplify, { Auth, Hub, Storage, API } from 'aws-amplify';
import { Button } from "@aws-amplify/ui-react";
import { printConsole } from './lib/utility'; 
import { downloadFile } from "./lib/common";
import { uploadFile } from "./lib/common";
import { useLocation } from 'react-router-dom';


const IronCondorOptionsLegs=({ signOut })=> {

  let email = useRef();

  const [forceRender, setForceRender]=useState(true);
  const sellCallStrikeRef = useRef(null);
 
  const location = useLocation();
  let optionsLegs = useRef(location.state?.optionsLegs);

  let optionsLegsOrg = useRef(location.state?.optionsLegs);
  console.log("IronCondorOptionsLegs : optionsLegsOrg = ", optionsLegsOrg.current);
  
  console.log("IronCondorOptionsLegs : tyepof optionsLegs = " + typeof(optionsLegs.current));
  console.log("IronCondorOptionsLegs : optionsLegs = ", optionsLegs.current);

  let marketSentiment = useRef({});
  
  function formatOptionsLegs (optionsLegs) {
    console.log("formatOptionsLegs : optionsLegs = ", optionsLegs);
    if (typeof (optionsLegs) === 'string') {
      console.log("formatOptionsLegs : optionsLegs is a string");
      //optionsLegs.current = JSON.stringify(optionsLegs.current);
      
      optionsLegs = optionsLegs.toString().replace("LONG", "\'LONG\'");
      optionsLegs = optionsLegs.toString().replace("SHORT", "\'SHORT\'");
      optionsLegs = optionsLegs.toString().replace(/'/g, '"');
      optionsLegs = optionsLegs.toString().replace(/NaN/g, 0);
      optionsLegs = optionsLegs.toString().replace(/\"\"/g, '"');
      optionsLegs = optionsLegs.toString().replace(/ : /g, ':');
      optionsLegs = optionsLegs.toString().replace(/, /g, ',');
      optionsLegs = optionsLegs.toString().replace(/: /g, ': ');
      //optionsLegs.current = JSON.parse(optionsLegs.current);
      try {
        // Attempt to parse it as JSON
        const parsed = JSON.parse(optionsLegs);
    
        // Check if the parsed value is an object
        if (typeof parsed === 'object' && parsed !== null) {
          // Only update if it's a valid object
          optionsLegs = parsed;
          optionsLegs["Open Cost"] = parseFloat(optionsLegs["Open Cost"]).toFixed(2);
          optionsLegs["Close Cost"] = parseFloat(optionsLegs["Close Cost"]).toFixed(2);
          optionsLegs["Profit Loss"] = parseFloat(optionsLegs["Profit Loss"]).toFixed(2);
          optionsLegs["Upper Breakeven"] = parseFloat(optionsLegs["Upper Breakeven"]).toFixed(2);
          optionsLegs["Lower Breakeven"] = parseFloat(optionsLegs["Lower Breakeven"]).toFixed(2);
          console.log("optionsLegs : Position = " + optionsLegs["Position"]);
          if (optionsLegs["Position"] === "LONG") {
            console.log("Position is Long");
            let callDiff = optionsLegs["Sell Call Strike"] - optionsLegs["Buy Call Strike"];
            console.log("callDiff = ", callDiff);
            let putDiff = optionsLegs["Buy Put Strike"] - optionsLegs["Sell Put Strike"];
            console.log("putDiff = ", putDiff);
            let maxDiff = Math.max(callDiff, putDiff);
            console.log("maxDiff = ", maxDiff);
            optionsLegs["Max Gain"] = (maxDiff * 100 + optionsLegs["Net Amount"] * 100) * optionsLegs["Contracts"];
            console.log("Max Gain = ", optionsLegs["Max Gain"]);
            
          } else {
            console.log("Position is Short");
            optionsLegs["Max Gain"] = optionsLegs["Net Amount"] * 100 * optionsLegs["Contracts"];
            console.log("Max Gain = ", optionsLegs["Max Gain"]);
          }
          console.log("formatOptionsLegs : optionsLegs = ", optionsLegs);
        } else {
          console.log("formatOptionsLegs : optionsLegs.current is not valid JSON");
          optionsLegs = {};
        }
      } catch (error) {
        console.log("formatOptionsLegs : Error parsing optionsLegs.current:", error);
        optionsLegs = {};
      }
    }
    console.log("formatOptionsLegs : tyepof optionsLegs = " + typeof(optionsLegs));
    return optionsLegs;
  }

  optionsLegs.current = formatOptionsLegs(optionsLegs.current);
 
  optionsLegsOrg.current = formatOptionsLegs(optionsLegsOrg.current);

  const [settingsStr, setSettingsStr]=useState({"days":"","security":"","upperBound":"", "lowerBound":"", "profitAlert":""});
  const [data,setData]=useState();
  const [error,setError]=useState('');
  const [displayMessage, setDisplayMessage] = useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  let updateDisplayForm = useRef(true);
  let [valueEdited, setValueEdited] = useState(1);
  let callStrikes = useRef([]);
  let putStrikes = useRef([]);
  let optionsLegsDetails = useRef([]);
  let [formData, setFormData] = useState({});
  let [input, setInput] = useState("");

  async function getUserInfo() {
    await printConsole("Executing getUserInfo");
    return (
      await new Promise((resolve, reject)=>{
        Auth.currentAuthenticatedUser().then(user=>{
          printConsole("getUserInfo : email = "+JSON.stringify(user.attributes.email));
          email.current = user.attributes.email;
          printConsole("getUserInfo : email = "+email.current);
        })
        setTimeout(() => resolve("done"), 1000);
      })
    )
  }

  let initString="";

  useEffect(()=>{
    navigate(input);
  },[input])

  useEffect(()=>{
    printConsole ("Executing useEffect");
    if (typeof(email.current) === 'undefined') {
      getUserInfo();
    }
    printConsole("useEffect : init = "+JSON.stringify(initString));
    executeSettingsForm();
    
  },[forceRender, submitted, settingsStr, data, valueEdited]);
  
  useEffect(() => {
    printConsole("Executing useEffect setting form data");
    if (optionsLegs.current) {
      try {
        const parsed = optionsLegs.current;
        if (typeof parsed === 'object' && parsed !== null) {
          optionsLegs.current = parsed;
          marketSentiment.current = analyzeBearBull(optionsLegs.current["Details"], optionsLegs.current["Last Sale"]);
          setFormData({
            "Sell Call Strike": optionsLegs.current["Sell Call Strike"],
            sellCallQuote: optionsLegs.current["Sell Call Quote"],
            "Buy Call Strike": optionsLegs.current["Buy Call Strike"],
            buyCallQuote: optionsLegs.current["Buy Call Quote"],
            "Buy Put Strike": optionsLegs.current["Buy Put Strike"],
            buyPutQuote: optionsLegs.current["Buy Put Quote"],
            "Sell Put Strike": optionsLegs.current["Sell Put Strike"],
            sellPutQuote: optionsLegs.current["Sell Put Quote"],
            "Contracts": optionsLegs.current["Contracts"]
          });

        }
      } catch (error) {
        console.log("Error parsing optionsLegs.current:", error);
      }
    }
  }, [optionsLegs.current]);

  useEffect (()=>{
    printConsole("Executing useEffect getting strike prices");
    console.log("useEffect getting strike prices : optionsLegs.current = ", optionsLegs.current);
    if (typeof(optionsLegs.current) === 'string') {
      optionsLegs.current = optionsLegs.current.toString().replace(/NaN/g, 0);
      optionsLegs.current = optionsLegs.current.toString().replace(/\"\"/g, '"');
      optionsLegs.current = optionsLegs.current.toString().replace(/ : /g, ':');
      optionsLegs.current = optionsLegs.current.toString().replace(/, /g, ',');
      optionsLegs.current = optionsLegs.current.toString().replace(/: /g, ': ');
      optionsLegs.current = "{" + optionsLegs.current + "}";
      console.log("useEffect getting strike prices : optionsLegs.current = ", optionsLegs.current);
      optionsLegs.current = JSON.parse(optionsLegs.current);
    }
    if (!optionsLegs.current["Details"]) {
      optionsLegs.current["Details"] = optionsLegsDetails.current;
      console.log("useEffect getting strike prices : optionsLegs.current = ", optionsLegs.current);
    }
    
    let optionsLegsJson = optionsLegs.current;
    
    console.log("useEffect : getting options details");
    optionsLegsDetails.current = optionsLegsJson.Details;
    console.log("useEffect : optionsLegsDetails = ", optionsLegsDetails.current);
    
    callStrikes.current = optionsLegsDetails.current
      .filter(option => option.type === "call")
      .map(option => option.strike);
    console.log("useEffect : callStrikes = ", callStrikes.current);
    
    putStrikes.current = optionsLegsDetails.current
      .filter(option => option.type === "put")
      .map(option => option.strike);
    console.log("useEffect : putStrikes = ", putStrikes.current);

  })

  function analyzeBearBull (optionsLegsDetails, lastSale) {
    console.log("Executing analyzeOpenInterestBearBull");
    console.log("analyzeOpenInterestBearBull : optionsLegsDetails = ", optionsLegsDetails);
    console.log("analyzeOpenInterestBearBull : optionsLegsDetails.length = ", optionsLegsDetails.length);
    console.log("analyzeOpenInterestBearBull : optionsLegsDetails[0] = ", optionsLegsDetails[0]);
    console.log("analyzeOpenInterestBearBull : lastSale = ", lastSale);
    let bearBullAnalysis = {};
    let openInterestAnalysis = "";
    let totalOpenInterestCalls = 0;
    let totalOpenInterestPuts = 0;
    let pcr = 0;
    let pcrAnalysis = "";
    let iv = "";
    let optionDeltaDistribution = "";
    let skewnessStrikePrices = "";
    let greeks = "";
    let strikeProximity = "";
    let bidAskSpread = "";

    for (let detInd = 0; detInd < optionsLegsDetails.length; detInd++) {

      if (optionsLegsDetails[detInd]) {
        if (optionsLegsDetails[detInd].type === "call") {
          if (optionsLegsDetails[detInd].openInterest) {
            totalOpenInterestCalls += optionsLegsDetails[detInd].openInterest;
          }
        } else {
          if (optionsLegsDetails[detInd].openInterest) {
            totalOpenInterestPuts += optionsLegsDetails[detInd].openInterest;
          }
        }
      }
    }
    console.log("analyzeOpenInterestBearBull : totalOpenInterestCalls = ", totalOpenInterestCalls);
    console.log("analyzeOpenInterestBearBull : totalOpenInterestPuts = ", totalOpenInterestPuts);

    if (totalOpenInterestCalls > totalOpenInterestPuts) {
      openInterestAnalysis = "Bullish";
    } else if (totalOpenInterestCalls < totalOpenInterestPuts) {
      openInterestAnalysis = "Bearish";
    } else {
      openInterestAnalysis = "Neutral";
    }

    if (totalOpenInterestCalls > 0 && totalOpenInterestPuts > 0) {
      pcr = totalOpenInterestPuts / totalOpenInterestCalls;
      if (pcr > 1) {
        pcrAnalysis = "Bearish";
      } else if (pcr < 1) {
        pcrAnalysis = "Bullish";
      } else {
        pcrAnalysis = "Neutral";
      }
    }

    iv = analyzeIvBearBull(optionsLegsDetails);
    optionDeltaDistribution = analyzeOptionDeltaDistribution(optionsLegsDetails);
    skewnessStrikePrices = analyzeSkewnessStrikePrices(optionsLegsDetails);
    greeks = analyzeGreeks(optionsLegsDetails);
    strikeProximity = strikeProximityBearBull(optionsLegsDetails, lastSale);
    bidAskSpread = analyzeBidAskSpread(optionsLegsDetails);

    bearBullAnalysis.openInterest = openInterestAnalysis;
    bearBullAnalysis.pcr = pcrAnalysis;
    bearBullAnalysis.iv = iv;
    bearBullAnalysis.optionDeltaDistribution = optionDeltaDistribution;
    bearBullAnalysis.skewnessStrikePrices = skewnessStrikePrices;
    bearBullAnalysis.greeks = greeks;
    bearBullAnalysis.strikeProximity = strikeProximity;
    bearBullAnalysis.bidAskSpread = bidAskSpread;
    bearBullAnalysis.overAll = overallSentimentBullBear(bearBullAnalysis);
    console.log("analyzeOpenInterestBearBull : bearBullAnalysis = ", bearBullAnalysis);

    return bearBullAnalysis;
  }

  const overallSentimentBullBear = (bearBullAnalysis) => {
    console.log("Executing overallSentimentBullBear");
    console.log("overallSentimentBullBear : bearBullAnalysis = ", bearBullAnalysis);
    // Convert the sentiment to numerical scores for calculation
    const sentimentScores = {
      "Bullish": 1,
      "Bearish": -1,
      "Neutral": 0
    };

    let analysisWeights = {
      "greeksWeight": 2,  // High importance
      "strikeProximityWeight": 1.5,  // Medium importance
      "openInterestWeight": 1,  // Medium-Low importance
      "optionDeltaDistributionWeight": 1.5,  // Medium importance
      "pcrWeight": 1,  // Medium-Low importance
      "ivWeight": 1.5,  // Medium-High importance
      "skewnessStrikePrices": 1,  // Medium-Low importance
      "bidAskSpreadWeight": 1.25  // Medium importance (added for bid-ask spread)
    }
  
    // Calculate the weighted score for each analysis
    let totalScore = 0;
    totalScore += sentimentScores[bearBullAnalysis.greeks] * analysisWeights.greeksWeight;
    totalScore += sentimentScores[bearBullAnalysis.strikeProximity] * analysisWeights.strikeProximityWeight;
    totalScore += sentimentScores[bearBullAnalysis.openInterest] * analysisWeights.openInterestWeight;
    totalScore += sentimentScores[bearBullAnalysis.optionDeltaDistribution] * analysisWeights.optionDeltaDistributionWeight;
    totalScore += sentimentScores[bearBullAnalysis.pcr] * analysisWeights.pcrWeight;
    totalScore += sentimentScores[bearBullAnalysis.iv] * analysisWeights.ivWeight;
    totalScore += sentimentScores[bearBullAnalysis.skewnessStrikePrices] * analysisWeights.skewnessStrikePrices;
    totalScore += sentimentScores[bearBullAnalysis.bidAskSpread] * analysisWeights.bidAskSpreadWeight;
    console.log("overallSentimentBullBear : totalScore = ", totalScore);
  
    // Determine overall sentiment based on the total score
    if (totalScore > 0) {
      return "Bullish";
    } else if (totalScore < 0) {
      return "Bearish";
    } else {
      return "Neutral";
    }
  };

  const analyzeBidAskSpread = (optionsLegsDetails) => {
    console.log("Executing analyzeBidAskSpread");
  
    if (!Array.isArray(optionsLegsDetails) || optionsLegsDetails.length === 0) {
      console.error('analyzeBidAskSpread: Invalid input: optionsLegsDetails is not an array or is empty.');
      return 'Neutral'; // Default to neutral if the array is empty or invalid.
    }
  
    // Separate call and put options
    const calls = optionsLegsDetails.filter(option => option.type === 'call' && option.bid !== undefined && option.ask !== undefined);
    const puts = optionsLegsDetails.filter(option => option.type === 'put' && option.bid !== undefined && option.ask !== undefined);
  
    if (calls.length === 0 || puts.length === 0) {
      console.error('analyzeBidAskSpread: Missing valid call or put data.');
      return 'Neutral';
    }
  
    // Calculate the average bid-ask spread for calls and puts
    const averageCallSpread = calls.reduce((sum, option) => sum + (option.ask - option.bid), 0) / calls.length;
    const averagePutSpread = puts.reduce((sum, option) => sum + (option.ask - option.bid), 0) / puts.length;
  
    console.log("analyzeBidAskSpread: averageCallSpread =", averageCallSpread);
    console.log("analyzeBidAskSpread: averagePutSpread =", averagePutSpread);
  
    // Analyze the spreads to determine sentiment
    if (averageCallSpread < averagePutSpread) {
      // Tighter spreads for calls indicate stronger demand and liquidity, suggesting bullish sentiment
      return 'Bullish';
    } else if (averagePutSpread < averageCallSpread) {
      // Tighter spreads for puts indicate stronger demand and liquidity, suggesting bearish sentiment
      return 'Bearish';
    } else {
      return 'Neutral'; // If the spreads are similar, the sentiment is neutral
    }
  };

  const strikeProximityBearBull = (optionsLegsDetails, underlyingPrice) => {
    // Identify the ATM strike (closest to the current underlying price)
    let atmStrike = optionsLegsDetails.reduce((prev, curr) => {
      return Math.abs(curr.strike - underlyingPrice) < Math.abs(prev.strike - underlyingPrice)
        ? curr
        : prev;
    });
  
    // Separate call and put options near the ATM strike
    const nearAtmCalls = optionsLegsDetails.filter(
      (option) =>
        option.type === "call" &&
        Math.abs(option.strike - atmStrike.strike) <= 5 // adjust range if needed
    );
  
    const nearAtmPuts = optionsLegsDetails.filter(
      (option) =>
        option.type === "put" &&
        Math.abs(option.strike - atmStrike.strike) <= 5 // adjust range if needed
    );
  
    // Calculate the sum of open interest for calls and puts near ATM
    const totalCallOpenInterest = nearAtmCalls.reduce(
      (sum, option) => sum + option.openInterest,
      0
    );
    const totalPutOpenInterest = nearAtmPuts.reduce(
      (sum, option) => sum + option.openInterest,
      0
    );
  
    // Determine the sentiment based on strike proximity and positioning
    if (totalCallOpenInterest > totalPutOpenInterest) {
      return "Bullish";
    } else if (totalPutOpenInterest > totalCallOpenInterest) {
      return "Bearish";
    } else {
      return "Neutral";
    }
  };

  const analyzeGreeks = (optionsLegsDetails) => {
    let totalCallDelta = 0;
    let totalPutDelta = 0;
    let totalCallGamma = 0;
    let totalPutGamma = 0;
    let totalCallVega = 0;
    let totalPutVega = 0;
  
    // Aggregate Greeks based on option type
    optionsLegsDetails.forEach((option) => {
      if (option.type === 'call') {
        if (option.delta) {
          totalCallDelta += option.delta;
        }
        if (option.gamma) {
          totalCallGamma += option.gamma;
        }
        if (option.vega) {
          totalCallVega += option.vega;
        }
      } else if (option.type === 'put') {
        if (option.delta) {
          totalPutDelta += option.delta;
        }
        if (option.gamma) {
          totalPutGamma += option.gamma;
        }
        if (option.vega) {
          totalPutVega += option.vega;
        }
      }
    });
  
    // Analyze sentiment based on aggregated Greeks
    const isBullish =
      totalCallDelta > Math.abs(totalPutDelta) &&
      totalCallGamma > totalPutGamma &&
      totalCallVega > totalPutVega;
  
    const isBearish =
      totalPutDelta < -Math.abs(totalCallDelta) &&
      totalPutGamma > totalCallGamma &&
      totalPutVega > totalCallVega;
  
    if (isBullish) {
      return 'Bullish';
    } else if (isBearish) {
      return 'Bearish';
    } else {
      return 'Neutral';
    }
  };

  const analyzeSkewnessStrikePrices = (optionsLegsDetails) => {
    console.log("Executing analyzeSkewnessStrikePrices");
    if (!Array.isArray(optionsLegsDetails) || optionsLegsDetails.length === 0) {
      console.error('analyzeSkewnessStrikePrices : Invalid input: optionsLegsDetails is not an array or is empty.');
      return 'Neutral'; // Default to neutral if the array is empty or invalid.
    }
  
    // Separate call and put options
    const calls = optionsLegsDetails.filter(option => option.type === 'call' && typeof option.impliedVolatility === 'number');
    const puts = optionsLegsDetails.filter(option => option.type === 'put' && typeof option.impliedVolatility === 'number');
  
    // Calculate the average implied volatility for calls and puts
    const averageCallIV = calls.reduce((sum, option) => sum + option.impliedVolatility, 0) / calls.length;
    const averagePutIV = puts.reduce((sum, option) => sum + option.impliedVolatility, 0) / puts.length;
  
    // Determine the skewness
    const skew = averagePutIV - averageCallIV;
  
    // Analyze the skewness to determine sentiment
    if (skew > 0.05) {
      console.log("analyzeSkewnessStrikePrices : skew = ", skew);
      return 'Bearish'; // Higher put IV relative to call IV indicates bearish sentiment
    } else if (skew < -0.05) {
      console.log("analyzeSkewnessStrikePrices : skew = ", skew);
      return 'Bullish'; // Higher call IV relative to put IV indicates bullish sentiment
    } else {
      console.log("analyzeSkewnessStrikePrices : skew = ", skew);
      return 'Neutral'; // Minimal skew suggests neutral sentiment
    }
  };

  function analyzeOptionDeltaDistribution(optionsLegsDetails) {
    console.log("Executing analyzeOptionDeltaDistribution");
    // Aggregate deltas for calls and puts
    let totalCallDelta = 0;
    let totalPutDelta = 0;
  
    console.log("analyzeOptionDeltaDistribution: optionsLegsDetails = ", optionsLegsDetails);
    optionsLegsDetails.forEach((option) => {
      if (option.type === "call") {
        if (option.delta) {
          totalCallDelta += option.delta;
        }
      } else if (option.type === "put") {
        if (option.delta) {
          totalPutDelta += option.delta;
        }
      }
    });
    console.log("analyzeOptionDeltaDistribution: totalCallDelta = ", totalCallDelta);
    console.log("analyzeOptionDeltaDistribution: totalPutDelta = ", totalPutDelta);
  
    // Calculate net delta
    const netDelta = totalCallDelta + totalPutDelta;
  
    // Determine market sentiment
    console.log("analyzeOptionDeltaDistribution : netDelta = ", netDelta);
    if (netDelta > 0.5) {
      return "Bullish";
    } else if (netDelta < -0.5) {
      return "Bearish";
    } else {
      return "Neutral";
    }
  }

  const analyzeIvBearBull = (options) => {
    let totalCallIV = 0;
    let totalPutIV = 0;
    let callCount = 0;
    let putCount = 0;
  
    options.forEach((option) => {
      if (option.type === "call") {
        if (option.impliedVolatility) {
          totalCallIV += option.impliedVolatility;
          callCount += 1;
        }
      } else if (option.type === "put") {
        if (option.impliedVolatility) {
          totalPutIV += option.impliedVolatility;
          putCount += 1;
        }
      }
    });
  
    const averageCallIV = callCount > 0 ? totalCallIV / callCount : 0;
    const averagePutIV = putCount > 0 ? totalPutIV / putCount : 0;
  
    // Determine sentiment
    if (averageCallIV < averagePutIV) {
      return "Bearish";
    } else if (averageCallIV > averagePutIV) {
      return "Bullish";
    } else {
      return "Neutral";
    }
  };

  const navigate = useNavigate();
  printConsole("Executing IronCondorOptionsLegs");

  const executeSettingsForm=async ()=>{
    if (typeof(email.current) === 'undefined') {
      getUserInfo();
    }
  };

  const handleSave = async ()=>{
    await printConsole("Executing handleSave");
    const myAPI="mktappapi";
    let path = '/saveIronCondorOptionsLegs'; 
    delete optionsLegs.current["Details"];
    optionsLegs.current["MKT Direction"] = marketSentiment.current;
    let optionsLegsStr = encodeURIComponent(JSON.stringify(optionsLegs.current));
    var passApiStr=await path + "/" + email.current + "/" + optionsLegsStr;
    await printConsole("handleSave : passApiStr = "+ passApiStr);
    await Promise.resolve(await API.get(myAPI, passApiStr))
      .then(async resp =>{
        await printConsole("handleSave : resp = " + resp);
        if (resp === "Saved Successfully") {
          setDisplayMessage(resp);
          setError("");
        } else {
          setError(resp);
          setDisplayMessage("");
        }
    })
    .catch(error => {
      setError(error.message);
      printConsole("saveLegs : Error = " + error.message);
    }) 
    
  }
  
  //const DisplayFields = () => {
    //printConsole("Executing DisplayFields");

    console.log("DisplayFields : updateDisplayForm.current = " + updateDisplayForm.current);
    console.log("DisplayFields : optionsLegs.current = ", optionsLegs.current);

    console.log("DisplayFields : formData = ", formData);

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    console.log("DisplayFields : typeof optionsLegs.current = " + typeof(optionsLegs.current));

    if (typeof (optionsLegs.current) === 'string') {
      console.log("DisplayFields : optionsLegs.current is a string");

      optionsLegs.current = optionsLegs.current.slice(1, -1);
  
      console.log("DisplayFields : optionsLegs.current = ", optionsLegs.current);
      
      try {
          optionsLegs.current = optionsLegs.current.toString().replace(/NaN/g, 0);
          optionsLegs.current = optionsLegs.current.toString().replace(/\"\"/g, '"');
          optionsLegs.current = optionsLegs.current.toString().replace(/ : /g, ':');
          optionsLegs.current = optionsLegs.current.toString().replace(/, /g, ',');
          optionsLegs.current = optionsLegs.current.toString().replace(/: /g, ': ');
          optionsLegs.current = "{" + optionsLegs.current + "}";
          optionsLegs.current = JSON.parse(optionsLegs.current);
          Object.keys(optionsLegs.current).forEach(key => {
            if (typeof optionsLegs.current[key] === 'number' && optionsLegs.current[key] !== "Profit") {
              optionsLegs.current[key] = formatter.format(optionsLegs.current[key]);
            }
          });
      } catch (error) {
          console.log("DisplayFields: Error parsing optionsLegs.current: " + error.message);
          console.log(error);
          return <div>Invalid options data</div>;
      }
    } 

    let fields = [];
    console.log ("DisplayFields : typeof optionsLegs.current = " + typeof(optionsLegs.current));
    if (optionsLegs.current && optionsLegs.current.Position) {
      
      let netDelta = parsePrice(optionsLegs.current["Delta Buy Call Strike"]) + parsePrice(optionsLegs.current["Delta Buy Put Strike"]) - parsePrice(optionsLegs.current["Delta Sell Call Strike"]) - parsePrice(optionsLegs.current["Delta Sell Put Strike"]);
      let netGamma = parsePrice(optionsLegs.current["Gamma Buy Call Strike"]) + parsePrice(optionsLegs.current["Gamma Buy Put Strike"]) - parsePrice(optionsLegs.current["Gamma Sell Call Strike"]) - parsePrice(optionsLegs.current["Gamma Sell Put Strike"]); 
      let netVega = parsePrice(optionsLegs.current["Vega Buy Call Strike"]) + parsePrice(optionsLegs.current["Vega Buy Put Strike"]) - parsePrice(optionsLegs.current["Vega Sell Call Strike"]) - parsePrice(optionsLegs.current["Vega Sell Put Strike"]);
      let netTheta = parsePrice(optionsLegs.current["Theta Buy Call Strike"]) + parsePrice(optionsLegs.current["Theta Buy Put Strike"]) - parsePrice(optionsLegs.current["Theta Sell Call Strike"]) - parsePrice(optionsLegs.current["Theta Sell Put Strike"]); 
      let profitLossPercent = (Math.abs(parseFloat(optionsLegs.current["Profit Loss"])) / parseFloat(optionsLegs.current["Open Cost"]) * 100).toFixed(2);
      if (optionsLegs.current.Position.toUpperCase() === "LONG") {
          fields = [
              { label: "Security", value: optionsLegs.current["Security"] },
              { label: "Last Sale", value: optionsLegs.current["Last Sale"] },
              { label: "Expiration", value: optionsLegs.current["Expiration"] },
              { label: "Net Amount", value: optionsLegs.current["Net Amount"] },
              { label: "Upper Breakeven", value: optionsLegs.current["Upper Breakeven"]},
              { label: "Lower Breakeven", value: optionsLegs.current["Lower Breakeven"]},
              { label: "Profit Loss %", value: profitLossPercent },
              { label: "Profit Loss $", value: optionsLegs.current["Profit Loss"]},
              { label: "Open Cost", value: optionsLegs.current["Open Cost"]},
              { label: "Close Cost", value: optionsLegs.current["Close Cost"]},
              { label: "Max Gain", value: parseFloat(optionsLegs.current["Max Gain"]).toFixed(2)},
              { label: "Max Loss", value: parseFloat(optionsLegs.current["Max Loss"]).toFixed(2)},
              { label: "Net Delta", value: netDelta.toFixed(2) },
              { label: "Net Gamma", value: netGamma.toFixed(2) },
              { label: "Net Vega", value: netVega.toFixed(2) },
              { label: "Net Theta", value: netTheta.toFixed(2) },
              { label: "VIX Price", value: optionsLegs.current["VIX Last Sale"].toString() },
              { label: "VIX Change %", value: optionsLegs.current["VIX Change %"] },
              { label: "MKT Direction", value: marketSentiment.current["overAll"] },
              { label: "Commission & Fee", value: parseFloat(optionsLegs.current["Commission Fee"]).toFixed(2) },
          ];
      } else if (optionsLegs.current.Position.toUpperCase() === "SHORT") {
          fields = [
              { label: "Security", value: optionsLegs.current["Security"] },
              { label: "Last Sale", value: optionsLegs.current["Last Sale"] },
              { label: "Expiration", value: optionsLegs.current["Expiration"] },
              { label: "Net Amount", value: optionsLegs.current["Net Amount"] },
              { label: "Upper Breakeven", value: optionsLegs.current["Upper Breakeven"]},
              { label: "Lower Breakeven", value: optionsLegs.current["Lower Breakeven"]},
              { label: "Profit Loss %", value: profitLossPercent },
              { label: "Profit Loss $", value: optionsLegs.current["Profit Loss"]},
              { label: "Open Cost", value: optionsLegs.current["Open Cost"]},
              { label: "Close Cost", value: optionsLegs.current["Close Cost"]},
              { label: "Max Gain", value: parseFloat(optionsLegs.current["Max Gain"]).toFixed(2)},
              { label: "Max Loss", value: parseFloat(optionsLegs.current["Max Loss"]).toFixed(2)},
              { label: "Net Delta", value: netDelta.toFixed(2) },
              { label: "Net Gamma", value: netGamma.toFixed(2) },
              { label: "Net Vega", value: netVega.toFixed(2) },
              { label: "Net Theta", value: netTheta.toFixed(2) },
              { label: "VIX Price", value: optionsLegs.current["VIX Last Sale"] },
              { label: "VIX Change %", value: optionsLegs.current["VIX Change %"] },
              { label: "MKT Direction", value: marketSentiment.current["overAll"] },
              { label: "Commission & Fee", value: parseFloat(optionsLegs.current["Commission Fee"]).toFixed(2) },
          ];
      }
    }

    function parsePrice(value) {
      // Remove '$' from the beginning or end, and replace commas with dots
      const numericValue = parseFloat(value.toString().replace(/[^0-9.-]+/g, '').replace(",", ".")); 
    
      return isNaN(numericValue) ? 0 : numericValue; 
    }

    const getFieldValueClass = (value) => {
      console.log("Executing getFieldValueClass");
      console.log("getFieldValueClass : value = ", value);
      if (value) {
        if (value.toString().includes("-")) {
          return "redText";
        }
        if (value.toString().includes("Bear")) {
          return "redText";
        }
      }
      //return value.toString().includes("-") || value.toString().includes("Bear") ? "redText" : "greenText";
      return "greenText";
    };

    const getRowGroups = (fields) => {
      let groupedFields = [];
      for (let i = 0; i < fields.length; i += 2) {
      //  if (fields[i].label === "Open Cost") {
      //     groupedFields.push(fields.slice(i, i + 3));
      //     i++;
      //  } else {
          groupedFields.push(fields.slice(i, i + 2));
       //}
      }
      console.log("getRowGroups : groupedFields = ", groupedFields);
      return groupedFields;
    };
  
    const renderField = (field, index) => {
      const fieldClass = (field.label === "Net Amount" || field.label === "Max Loss" || field.label === "Open Cost" || field.label === "Close Cost" || field.label === "Profit Loss $"
                          || field.label === "Profit Loss %" || field.label === "Max Gain" || field.label === "VIX Change %" || field.label === "MKT Direction")
          ? getFieldValueClass(field.value)
          : "";
          const tooltips = {
            "Net Delta": `
              Net delta indicates the overall directional exposure of your options portfolio to changes in the underlying asset's price.
        
              Interpretation:
              - A positive net delta means your portfolio will generally benefit if the underlying asset's price increases.
              - A negative net delta means your portfolio will generally benefit if the underlying asset's price decreases.
              - A net delta near zero suggests a relatively neutral position, with limited sensitivity to price changes (at least in the short term).This means your portfolio is relatively insensitive to small fluctuations in the underlying asset's price. You won't make significant gains or losses if the price moves moderately in either direction.
            `,
            "Net Gamma": `
              Net gamma measures the rate of change of your portfolio's delta in response to changes in the underlying asset's price.
        
              Interpretation:
              - A positive net gamma means your delta will become more positive as the price rises, or more negative as the price falls. This indicates increasing directional exposure.
              - A negative net gamma means your delta will become less positive as the price rises, or less negative as the price falls. This indicates decreasing directional exposure.
            `,
            "Net Vega": `
              Net vega represents the overall sensitivity of your options portfolio to changes in implied volatility (the market's expectation of future price fluctuations).
        
              Interpretation:
              - A positive net vega means your portfolio will generally benefit if implied volatility increases.
              - A negative net vega means your portfolio will generally benefit if implied volatility decreases.
            `,
            "Net Theta": `
              Net theta reflects the rate at which your options portfolio loses value due to the passage of time (time decay).
        
              Interpretation:
              - A negative net theta indicates that your portfolio is losing value as time passes. This is common for options buyers.
              - A positive net theta indicates that your portfolio is gaining value as time passes. This is common for options sellers.
            `,
            "MKT Direction": `
              High
              -----
                Greeks - ${marketSentiment.current["greeks"]}

              Medium - High
              ---------------
                Strike Proximity - ${marketSentiment.current["strikeProximity"]}
                Options Delta Distribution - ${marketSentiment.current["optionDeltaDistribution"]}
                Implied Volatility - ${marketSentiment.current["iv"]}

              Medium - Low
              --------------
                Bid Ask Spread - ${marketSentiment.current["bidAskSpread"]}
                
              Low
              ----
                Open Interest - ${marketSentiment.current["openInterest"]}
                Put Call Ratio - ${marketSentiment.current["pcr"]}
                Skewness Strike Prices - ${marketSentiment.current["skewnessStrikePrices"]}
            `,
          };
      return (
        <div key={index} style={{ flex: '1 0 33%', padding: '10px' }} className="field-frame" title={tooltips[field.label] || ""}>
            <strong>{field.label}: </strong> 
            <span className={fieldClass}>{field.value}</span>
        </div>
      );
    };
  
    const handleChange = (e) => {
      console.log("Executing handleChange");
      e.preventDefault();
      const { name, value } = e.target;
      console.log("handleChange : name = " + name + " value = " + value);
      console.log("handleChange : optionsLegsOrg.current = ", optionsLegsOrg.current);
      console.log("handleChange : formData = ", formData);

      if (name === "Contracts") {
        if (value === "") {
          optionsLegs.current["Contracts"] = ""
        } else {
          let contracts = parseInt(value);
          optionsLegs.current["Contracts"] = contracts;
          optionsLegs.current["Commission Fee"] = optionsLegsOrg.current["Commission Fee"] * contracts;
          optionsLegs.current["Profit Loss"] = parseFloat(optionsLegsOrg.current["Profit Loss"]) * contracts;
          optionsLegs.current["Open Cost"] = parseFloat(optionsLegsOrg.current["Open Cost"]) * contracts;
          optionsLegs.current["Close Cost"] = parseFloat(optionsLegsOrg.current["Close Cost"]) * contracts;
          optionsLegs.current["Max Gain"] = parseFloat(optionsLegsOrg.current["Max Gain"]) * contracts;
          optionsLegs.current["Max Loss"] = parseFloat(optionsLegsOrg.current["Max Loss"]) * contracts;
        }
      }

      let sellCallStrike = {};
      let buyCallStrike = {};
      let buyPutStrike = {};
      let sellPutStrike = {};

      if (name === "Sell Call Strike") optionsLegs.current["Sell Call Strike"] = parseFloat(value);
      if (name === "Buy Call Strike") optionsLegs.current["Buy Call Strike"] = parseFloat(value);
      if (name === "Buy Put Strike") optionsLegs.current["Buy Put Strike"] = parseFloat(value);
      if (name === "Sell Put Strike") optionsLegs.current["Sell Put Strike"] = parseFloat(value);

      sellCallStrike = getDetailsStrikeChange ("Call", optionsLegs.current["Sell Call Strike"], optionsLegs.current);
      console.log("sellCallStrike = ", sellCallStrike);
      buyCallStrike = getDetailsStrikeChange ("Call", optionsLegs.current["Buy Call Strike"], optionsLegs.current);
      console.log("buyCallStrike = ", buyCallStrike);
      buyPutStrike = getDetailsStrikeChange ("Put", optionsLegs.current["Buy Put Strike"], optionsLegs.current);
      console.log("buyPutStrike = ", buyPutStrike);
      sellPutStrike = getDetailsStrikeChange ("Put", optionsLegs.current["Sell Put Strike"], optionsLegs.current);
      console.log("sellPutStrike = ", sellPutStrike);

      setFormData(prevValues => ({
        ...prevValues,
        "Contracts": optionsLegs.current.Contracts,
        "Sell Call Strike": sellCallStrike.strike,
        "Buy Call Strike": buyCallStrike.strike,
        "Buy Put Strike": buyPutStrike.strike,
        "Sell Put Strike": sellPutStrike.strike,
        "sellCallQuote": sellCallStrike.bid,
        "buyCallQuote": buyCallStrike.ask,
        "buyPutQuote": buyPutStrike.ask,
        "sellPutQuote": sellPutStrike.bid,
      }));
    };

    function getDetailsStrikeChange (strikeName, strikePrice, optionsLegs) {
      console.log("Executing getDetailsStrikeChange");
      if (strikeName.toString().toLowerCase().trim().indexOf("call") >= 0 ) {
        console.log("strikeName = ", strikeName);
        for (let indDetails = 0; indDetails < optionsLegs.Details.length; indDetails++) {
          if (optionsLegs.Details[indDetails].strike === strikePrice && optionsLegs.Details[indDetails].type === "call") {
            return optionsLegs.Details[indDetails]
          }
        }
      }
      if (strikeName.toString().toLowerCase().trim().indexOf("put") >= 0 ) {
        console.log("strikeName = ", strikeName);
        for (let indDetails = 0; indDetails < optionsLegs.Details.length; indDetails++) {
          if (optionsLegs.Details[indDetails].strike === strikePrice && optionsLegs.Details[indDetails].type === "put") {
            return optionsLegs.Details[indDetails]
          }
        }
      }
      return {};
    }
  
    const handleSubmit = async(e) => {
      await console.log("Executing handleSubmit");
      await e.preventDefault();
      await console.log("handleSubmit : e = ", e);
      await setDisplayMessage("Recalculating");
      let sellCallStrike, buyCallStrike, buyPutStrike, sellPutStrike, contracts;


      for (let targetInd = 0; targetInd < e.target.length; targetInd++ ) {
        if (e.target[targetInd].name === "Sell Call Strike") {
          sellCallStrike = e.target[targetInd].value;
          optionsLegs.current["Sell Call Strike"] = sellCallStrike;
        }
        if (e.target[targetInd].name === "Buy Call Strike") {
          buyCallStrike = e.target[targetInd].value;
          optionsLegs.current["Buy Call Strike"] = buyCallStrike;
        }
        if (e.target[targetInd].name === "Buy Put Strike") {
          buyPutStrike = e.target[targetInd].value;
          optionsLegs.current["Buy Put Strike"] = buyPutStrike;
        }
        if (e.target[targetInd].name === "Sell Put Strike") {
          sellPutStrike = e.target[targetInd].value;
          optionsLegs.current["Sell Put Strike"] = sellPutStrike;
        }
        if (e.target[targetInd].name === "Contracts") {
          contracts = e.target[targetInd].value;
          optionsLegs.current["Contracts"] = contracts;
        }
      }
      
      await console.log("handleSubmit : sell call strike = " + sellCallStrike);
      await console.log("handleSubmit : buy call strike = " + buyCallStrike);
      await console.log("handleSubmit : buy put strike = " + buyPutStrike);
      await console.log("handleSubmit : sell put strike = " + sellPutStrike);

      await console.log("handleSubmit : optionsLegs.current = ", optionsLegs.current);

      const myAPI="mktappapi";
      let path = '/getIronCondorOptionsLegsRefresh'; 
      var passApiStr=await path + "/" + optionsLegs.current.Security + "/" + optionsLegs.current.Expiration + "/" + optionsLegs.current["Sell Call Strike"] + "/" + optionsLegs.current["Buy Call Strike"] +
        "/" + optionsLegs.current["Buy Put Strike"] + "/" + optionsLegs.current["Sell Put Strike"] + "/" + optionsLegs.current.Position.toUpperCase().trim() + "/" + optionsLegs.current.Contracts + "/" + optionsLegs.current.Profit + "/" +
        optionsLegs.current.Upper + "/" + optionsLegs.current.Lower + "/" + optionsLegs.current.Days;
      await printConsole("handleSubmit : passApiStr = "+ passApiStr);
      await Promise.resolve(await API.get(myAPI, passApiStr))
        .then(async resp =>{
          await printConsole("handleSubmit : resp = " + resp);
          optionsLegs.current = await resp;
          await printConsole("handleSubmit : optionsLegs.current = " + JSON.stringify(optionsLegs.current));
          await setDisplayMessage("");
          await setFormData(prevValues => ({
            ...prevValues,
            sellCallQuote: optionsLegs.current["Sell Call Quote"],
            buyCallQuote: optionsLegs.current["Buy Call Quote"],
            buyPutQuote: optionsLegs.current["Buy Put Quote"],
            sellPutQuote: optionsLegs.current["Sell Put Quote"]
          }));
          setError("");
      })
      .catch(error => {
        setError(error.message);
        printConsole("handleSubmit : Error = " + error.message);
      }) 
    };

    const handleRefresh = async () => {
      //e.preventDefault();
      console.log("Executing handleRefresh");
      console.log("handleRefresh : formData = ", formData);
      console.log("handleRefresh : optionsLegs.current = ", optionsLegs.current);
      
      const myAPI="mktappapi";
      let path = '/getIronCondoroptionsLegsRefresh'; 
      var passApiStr=await path + "/" + optionsLegs.current.Security + "/" + optionsLegs.current.Expiration + "/" + optionsLegs.current["Sell Call Strike"] + "/" + optionsLegs.current["Buy Call Strike"] +
        "/" + optionsLegs.current["Buy Put Strike"] + "/" + optionsLegs.current["Sell Put Strike"] + "/" + optionsLegs.current.Position.toUpperCase().trim() + "/" + optionsLegs.current.Contracts + "/" + optionsLegs.current.Profit + "/" +
        optionsLegs.current.Upper + "/" + optionsLegs.current.Lower + "/" + optionsLegs.current.Days;
      await printConsole("handleRefresh : passApiStr = "+ passApiStr);
      await Promise.resolve(await API.get(myAPI, passApiStr))
        .then(async resp =>{
          await printConsole("handleRefresh : resp = " + resp);
          optionsLegs.current.current = await resp;
          await printConsole("handleRefresh : optionsLegs.current = " + JSON.stringify(optionsLegs.current.current));
          setError("");
      })
      .catch(error => {
        //setError(error.message);
        printConsole("getLegs : Error = " + error.message);
      }) 

    }

    let maxOptions = 15;

    return (
      <div>
        {error && <div className="blink_me_red">{error}</div>}
        {displayMessage && <div className="blink_me">{displayMessage}</div>}
        {fields && getRowGroups(fields).map((group, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'center', width: '60%', margin: '0 auto' }}>
            
              {group.map(renderField)}
              {group.length === 1 && <div style={{ flex: '1 0 33%'}}></div>} 
         
          </div>
        ))}
        <form onSubmit={handleSubmit} className="formStyleOptionsLegs">
        <div style={{ display: 'flex', flexDirection: 'column', width: '60%', margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '0 auto' }}>
            <label className="labelStyleOptionsLegs label1"><strong>Contracts:</strong></label>
            <input
              className="inputStyleOptionsLegs input1"
              type="numeric"
              name="Contracts"
              value={formData["Contracts"]}
              onChange={handleChange}
              required
            />
          </div>  
        </div>  
        <div style={{ display: 'flex', flexDirection: 'column', width: '60%', margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '0 auto' }}>
            <label className="labelStyleOptionsLegs label1"><strong>Sell Call Strike:</strong></label>

            <select
              className={`inputStyleOptionsLegs input1`}
              name="Sell Call Strike"
              value={formData["Sell Call Strike"]}
              onChange={handleChange}
              
            >

              {callStrikes.current.map(price => (

                <option key={price} value={price}>{price}</option>

              ))}
              
            </select>

            <label className="labelStyleOptionsLegs label2"><strong>Quote:</strong></label>
            <input
              className="inputStyleOptionsLegs input2"
              type="text"
              name="sellCallQuote"
              value={formData.sellCallQuote}
              readOnly
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
            <label className="labelStyleOptionsLegs label3"><strong>Buy Call Strike:</strong></label>
            <select
              className="inputStyleOptionsLegs input3"
              name="Buy Call Strike"
              value={formData["Buy Call Strike"]}
              onChange={handleChange}
            >
              {callStrikes.current.map(price => (
                <option key={price} value={price}>{price}</option>
              ))}
            </select>
            <label className="labelStyleOptionsLegs label4"><strong>Quote:</strong></label>
            <input
              className="inputStyleOptionsLegs input4"
              type="text"
              name="buyCallQuote"
              value={formData.buyCallQuote}
              readOnly
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
            <label className="labelStyleOptionsLegs label5"><strong>Buy Put Strike:</strong></label>
            <select
              className="inputStyleOptionsLegs input5"
              name="Buy Put Strike"
              value={formData["Buy Put Strike"]}
              onChange={handleChange}
            >
              {putStrikes.current.map(price => (
                <option key={price} value={price}>{price}</option>
              ))}
            </select>
            <label className="labelStyleOptionsLegs label6"><strong>Quote:</strong></label>
            <input
              className="inputStyleOptionsLegs input6"
              type="text"
              name="buyPutQuote"
              value={formData.buyPutQuote}
              readOnly
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
            <label className="labelStyleOptionsLegs label7"><strong>Sell Put Strike:</strong></label>
            <select
              className="inputStyleOptionsLegs input7"
              name="Sell Put Strike"
              value={formData["Sell Put Strike"]}
              onChange={handleChange}
            >
              {putStrikes.current.map(price => (
                <option key={price} value={price}>{price}</option>
              ))}
            </select>
            <label className="labelStyleOptionsLegs label8"><strong>Quote:</strong></label>
            <input
              className="inputStyleOptionsLegs input8"
              type="text"
              name="sellPutQuote"
              value={formData.sellPutQuote}
              readOnly
            />
          </div>
          <Button className="buttonStyle" type="submit">Recalculate</Button>
        </div>
      </form>
        <div className='MenuStyle' style={{
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          justifyContent: 'center', 
          
        }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '20%', margin: '0 auto' }}>
            <Button className="buttonStyle" onClick={() => { handleSave(); } }>Save</Button>
            <Button className="buttonStyle"  onClick={()=>{setInput("/myPortfolioIronCondor"); }}>IC Portfolio</Button>
          </div>
        </div>
      </div>
    );
  //}

  /*return (
    <div>
      {optionsLegs.current && <DisplayFields optionsLegs={optionsLegs.current} />}
    </div>
  );*/
}

export default IronCondorOptionsLegs;